<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Link Follow-Up Event after {{event.name}}</v-card-title>

      <v-alert v-if="event.next_event_id" type="success">
        This event is already linked to {{ event.next_event_id }}.
      </v-alert>

      <v-card-text>      
        <p>
          Link a follow-up event to {{event.name}}. This allows participants to quickly find and join the next event.
        </p>

        <v-select
          v-model="request.next_event_id"
          :items="events"
          item-value="id"
          item-text="name"
          label="Follow-up Event"
          />
        <v-switch
          v-model="request.copy_registrations"
          label="Copy registrations"
          persistent-hint
          hint="Enable this option to copy all registration data to the follow-up event."
          />
        <v-switch
          v-model="request.auto_join"
          label="Auto-join participants"
          persistent-hint
          hint="Enable this option to automatically join participants of this event to the selected follow-up event."
          />

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" :disabled="request.next_event_id == null || event.next_event_id != null" @click="linkNextEvent" :loading="$store.getters.isLoading">Link Event</v-btn>
      </v-card-actions>
          
    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "IntegrationsEventsCom",
  components: {
    Header,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      tenant: tenant,
      event: null,
      events: null,
      request: {},
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
    },

    async loadData() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
        this.events = (await eventManagerService.getOwnedEvents()).data.data;
      }
    },

    async linkNextEvent() {
      const response = (await eventManagerService.linkNext(this.event.id, this.request)).data;
      this.$helpers.toastResponse(this, response, 'Successfully linked event.');
      if (response.status == 'OK') {
        this.$router.push({name:'eventmanagerView', params: {id: this.event.id}});
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Link Follow-Up Event', disabled: true },
      ];
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

